import React from 'react'
// import styled from '@emotion/styled'
import tw from 'tailwind.macro'
import BlockContent from '../block-content'
// import Container from '../container'

// import styles from '../project.module.css'

function Contact(props) {
  const ContactTitle = tw.h1`
  mt-2 px-0 pt-6 pb-4 text-xl md:text-2xl lg:text-3xl font-normal
  `
  const ContactBody = tw.div`
  pb-4 leading-normal
  `
  const SectionContainer = tw.section`
  text-black max-w-7xl w-full font-display px-4
  `

  return (
    <SectionContainer>
      <div>
        <ContactTitle>Contact HireRight</ContactTitle>
        {props.contact_us && (
          <ContactBody className="contact">
            <BlockContent blocks={props._rawContactUs || []} />
          </ContactBody>
        )}
      </div>
    </SectionContainer>
  )
}

export default Contact
